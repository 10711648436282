import { StyleProvider } from "@ant-design/cssinjs";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { message, notification } from "antd";
import "antd/dist/reset.css";
import JSONBig from 'json-bigint';
import React, { useCallback } from "react";
import ReactDOM from "react-dom/client";
import { useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { BASE_PATH } from "./constants/routes.js";
import "./index.css";
import { GlobalProvider } from "./lib/contexts/GlobalContext.js";
import { TranslationProvider } from "./lib/contexts/TranslationContext.js";
import App from "./pages/AppPages/App.js";
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

// 定义 JSONBig 实例
const jsonBig = JSONBig({ storeAsString: true });
// 全局覆盖 JSON 的 stringify 和 parse 方法
window.JSON.stringify = jsonBig.stringify.bind(jsonBig);
window.JSON.parse = jsonBig.parse.bind(jsonBig);

// 自定義 hook 來處理錯誤和導航
const useErrorHandler = (messageApi: any) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation('Global');
  const defaultNameSpace = 'Global';
  const customTranslate = useCallback((key: string, nameSpace: string = defaultNameSpace): string => {
    if (!key) return '';
    const translatedString = i18n.t(key.toLowerCase(), { ns: nameSpace });
    console.log(translatedString);
    return translatedString === key.toLowerCase() ? key : translatedString;
  }, [i18n]);

  const handleError = useCallback(async (error: unknown) => {
    if (error instanceof Error) {
      if (error.message === 'Unauthorized') {
        window.location.href = `${BASE_PATH}/login`;
      } else if (error.message === 'Forbidden') {
        messageApi.error(customTranslate('Forbidden'));
        navigate(`${BASE_PATH}/`);
      } else {
        messageApi.error(customTranslate(error.message));
      }
    }
  }, [navigate, messageApi, customTranslate]);

  return handleError;
};

// Root 組件
const Root: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage({
    duration: 2,
    maxCount: 2,
  });
  const [notificationApi, notificationContextHolder] = notification.useNotification({
    duration: 2,
    maxCount: 2,
    placement: 'bottomLeft',
  });

  const handleError = useErrorHandler(messageApi);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 0,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKeyHashFn: (queryKey) => {
          // 將 queryKey 轉換為可序列化的格式
          return JSONBig.stringify(queryKey);
        },
        retry: false,
        onError: handleError,
      },
      mutations: {
        retry: false,
        onError: handleError,
      }
    },
  });

  return (
    <GoogleOAuthProvider clientId={`${process.env.GoogleClientId}`}>
      <StyleProvider>
        <QueryClientProvider client={queryClient}>
          <GlobalProvider messageApi={messageApi} notificationApi={notificationApi}>
            <TranslationProvider>
              {contextHolder}
              {notificationContextHolder}
              <App />
            </TranslationProvider>
          </GlobalProvider>
        </QueryClientProvider>
      </StyleProvider>
    </GoogleOAuthProvider>
  );
};

// 主應用包裝器
const AppWrapper: React.FC = () => (
  <BrowserRouter>
    <Root />
  </BrowserRouter>
);

ReactDOM.createRoot(document.getElementById("root")!).render(<AppWrapper />);