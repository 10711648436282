import { DeviceType, MerchantPortalStyleSettingType, MerchantPortalStyleShopComponents } from '@/Templates/enums/templateEnums';
import routes, { BASE_PATH } from '@/constants/routes';
import { IRoute } from '@/interfaces/Router';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useMutation } from 'react-query';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { IGlobalContextType, IGlobalProviderProps } from '../../interfaces/GlobalContextType';
import { useGetShoppingCartCountApi, useGetTempShoppingCartCountApi } from '../api/Portal/items';
import { useValidateLoginApi } from '../api/Portal/members';
import { useGetMerchantGoogleIntegrateSettingDtoApi } from '../api/Portal/merchant';
import { useGetMerchantPortalOptionSettingApi, useGetMerchantPortalStyleSettingsApi } from '../api/apis';
import { LocalStorageHelper } from '../helpers/LocalStorageHelper';

export const GlobalContext = React.createContext<IGlobalContextType>(null!);


export const GlobalProvider: React.FC<IGlobalProviderProps> = ({ messageApi, notificationApi, children }): React.ReactNode => {
  const [isGlobalContextInitialized, setIsGlobalContextInitialized] = useState<boolean>(false);
  const localStorageHelper = new LocalStorageHelper();
  const navigate = useNavigate();
  const [maxWidth, setMaxWidth] = useState<string>('1340px');
  const [merchantId, setMerchantId] = useState<BigInt>(BigInt(0));
  const [systemConfig, setSystemConfig] = useState<IGlobalContextType['systemConfig']>({ Roles: [] });
  const [isHideNavBar, setIsHideNavBar] = useState<IGlobalContextType['isHideNavBar']>(false);
  const [isHideAnnouncement, setIsHideAnnouncement] = useState<IGlobalContextType['isHideAnnouncement']>(false);
  const [isHideFunctionalFloatButtonGroup, setIsHideFunctionalFloatButtonGroup] = useState<IGlobalContextType['isHideFunctionalFloatButtonGroup']>(false);
  const [navBarHeight, setNavBarHeight] = useState<IGlobalContextType['navBarHeight']>(0);
  const [navBarGap, setNavBarGap] = useState<IGlobalContextType['navBarGap']>(0);
  const [isFullPageLoading, setIsFullPageLoading] = useState<IGlobalContextType['isFullPageLoading']>(true);
  const [deviceType, setDeviceType] = useState<IGlobalContextType['deviceType']>(DeviceType.Desktop);
  const [shoppingCartItemCount, setShoppingCartItemCount] = useState<IGlobalContextType['shoppingCartItemCount']>(0);
  const [userAgent, setUserAgent] = useState<string>(navigator.userAgent);
  const [globalPage, setGlobalPage] = useState<IRoute>();
  const [isHideFooter, setIsHideFooter] = useState<boolean>(true);
  const merchantPortalOptionSettingMutation = useMutation(async (params: { merchantId: BigInt, signal?: AbortSignal }) => await useGetMerchantPortalOptionSettingApi(params.merchantId, params.signal));
  const merchantPortalStyleSettingsMutation = useMutation(async (params: { merchantId: BigInt, signal?: AbortSignal }) => await useGetMerchantPortalStyleSettingsApi(params.merchantId, params.signal));
  const initAppMutation = useMutation('InitApp', async (params: { merchantId: BigInt, signal?: AbortSignal }) => {
    await merchantPortalStyleSettingsMutation.mutateAsync(params
      , {
        onSuccess: async (response) => {
          if (response?.result?.find(r => r.type === MerchantPortalStyleSettingType.Components && r.name === MerchantPortalStyleShopComponents.FullPageLoading)?.styleSetting?.isEnable) {
            setIsFullPageLoading(true);
            await merchantPortalOptionSettingMutation.mutateAsync({ merchantId: merchantId, signal: params.signal });
            await new Promise(resolve => setTimeout(resolve, 3000)).then(() => {
              setIsFullPageLoading(false);
              setIsHideFooter(false);

              // 處理加載動畫的淡出和移除
              const logoElement = document.getElementById('loading-logo');
              if (logoElement && logoElement.parentElement) {
                logoElement.parentElement.style.transition = 'opacity 2s ease-in-out';
                logoElement.parentElement.style.opacity = '0';

                logoElement.parentElement.addEventListener('transitionend', () => {
                  logoElement.parentElement?.remove();
                });
              }
            });
          }
          else {
            await merchantPortalOptionSettingMutation.mutateAsync({ merchantId: merchantId, signal: params.signal });
            setIsHideFooter(false);

            // 處理加載動畫的淡出和移除
            const logoElement = document.getElementById('loading-logo');
            if (logoElement && logoElement.parentElement) {
              logoElement.parentElement.style.transition = 'opacity 2s ease-in-out';
              logoElement.parentElement.style.opacity = '0';

              logoElement.parentElement.addEventListener('transitionend', () => {
                logoElement.parentElement?.remove();
              });
            }
          }
        },
      }
    )
  });
  const { mutate: getShoppingCartItemCountMutate } = useMutation(async (isLogin: boolean) => {
    if (isLogin) {
      var response = await useGetShoppingCartCountApi();
      if (response.isSuccess) {
        return response.result;
      }
      return 0;
    } else {
      var response = await useGetTempShoppingCartCountApi(merchantId, tryGetSessionId());
      if (response.isSuccess) {
        return response.result;
      }
      return 0;
    }
  },
    {
      onSuccess: (count) => {
        if (count)
          setShoppingCartItemCount(count);
        else
          setShoppingCartItemCount(0);
      }
    }
  );
  const { mutate: useGetMerchantGoogleIntegrateSettingDtoApiMutate } = useMutation(async (merchantId: BigInt) => useGetMerchantGoogleIntegrateSettingDtoApi(merchantId), {
    onSuccess: (response) => {
      if (response.isSuccess && response.result) {
        var data = response.result;
        console.log(data);
        if (data) {
          if (data.enable_GA === false || data.gA_TrackingId === '') {
            return;
          }
          // 初始化 Google Analytics
          console.log('Google Analytics is initialized');
          ReactGA.initialize([{ trackingId: data.gA_TrackingId }]);
        }
      }
    }
  });
  const [isLogin, setIsLogin] = useState<IGlobalContextType['isLogin']>(() => {
    const token = localStorage.getItem("JWTToken");
    return token !== null;
  });
  const vilidateIsLogin = async () => {
    var isLogin = await useValidateLoginApi(merchantId);
    if (!isLogin.isSuccess) {
      localStorage.removeItem("JWTToken");
    }
    setIsLogin(isLogin.isSuccess);
  }
  const updateSystemConfig = (config: { Roles: string[] }) => {
    setSystemConfig(config);
  };
  const updateNavBarHeight = (height: number) => {
    setNavBarHeight(height);
  };
  const updateIsLogin = (isLogin: boolean) => {
    setIsLogin(isLogin);
  };
  const updateDeviceType = (deviceType: DeviceType) => {
    setDeviceType(deviceType);
  };
  const sendGA_Event = (optionName: string, params: any): void => {
    if (ReactGA.isInitialized === false) {
      return;
    }
    ReactGA.event(optionName, params);
  }
  const handleLogout = () => {
    localStorage.removeItem("JWTToken");
    setIsLogin(false);
    refreshShoppingCartItemCount(false);
    navigate(`${BASE_PATH}/`);
  }

  useEffect(() => {
    //Get merchantId first
    const fetchMerchantId = async () => {
      try {
        const id = await window.GlobalVars.getMerchantId();
        if (id === '') {
          // Redirect to the error page and refresh
          //   window.location.href = `${window.location.origin}/error`;
          navigate(`${BASE_PATH}/error`, { replace: true });
          return;
        }
        setMerchantId(BigInt(id));
      } catch (error) {
        navigate(`${BASE_PATH}/error`, { replace: true });
      }
    };

    //Validate if is global page 
    var page = Object.keys(routes).find((key) => window.location.pathname === routes[key].path);
    if (page && routes[page].isGlobalPage) {
      setGlobalPage(routes[page]);
      return;
    }

    fetchMerchantId();
  }, []);

  useEffect(() => {
    if (merchantId === BigInt(0)) {
      return;
    }
    vilidateIsLogin();
    tryGetSessionId();
    useGetMerchantGoogleIntegrateSettingDtoApiMutate(merchantId);
    setIsGlobalContextInitialized(true);
  }, [merchantId]);

  function tryGetSessionId(): BigInt {
    const storedSessionId = BigInt(localStorageHelper.getWithExpiry<string>('sd') || Date.now());
    localStorageHelper.setWithExpiry('sd', storedSessionId.toString(), 7 * 24 * 60 * 60 * 1000); // 7天的毫秒数
    return storedSessionId;
  }

  function refreshShoppingCartItemCount(isLogin: boolean) {
    getShoppingCartItemCountMutate(isLogin);
  }

  if (globalPage) {
    return <Routes>
      <Route key={globalPage.path} path={globalPage.path} element={globalPage.element} />
    </Routes>
  }

  return (
    <GlobalContext.Provider value={{
      initAppMutation: initAppMutation,
      maxWidth, setMaxWidth,
      merchantId,
      isLogin, setIsLogin: updateIsLogin,
      messageApi,
      notificationApi,
      systemConfig, setSystemConfig: updateSystemConfig,
      isHideNavBar, setIsHideNavBar,
      isHideAnnouncement, setIsHideAnnouncement,
      isHideFunctionalFloatButtonGroup, setIsHideFunctionalFloatButtonGroup,
      navBarHeight, setNavBarHeight: updateNavBarHeight,
      navBarGap, setNavBarGap,
      isFullPageLoading: isFullPageLoading, setIsFullPageLoading: setIsFullPageLoading,
      deviceType, setDeviceType: updateDeviceType,
      merchantPortalOptionSettingMutation: merchantPortalOptionSettingMutation,
      tryGetSessionId,
      shoppingCartItemCount,
      refreshShoppingCartItemCount: refreshShoppingCartItemCount,
      userAgent: userAgent, // 提供userCountry,
      sendGAEvent: sendGA_Event,
      isHideFooter,
      setIsHideFooter,
      merchantPortalStyleSettingsMutation: merchantPortalStyleSettingsMutation,
      handleLogout: handleLogout
    }}>
      {
        !isGlobalContextInitialized ? <></> : children
      }
    </GlobalContext.Provider>
  );
};
