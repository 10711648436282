import { MerchantPortalStyleSettingType, MerchantPortalStyleShopComponents } from "@/Templates/enums/templateEnums";

import dynamic from "next/dynamic";
import React, { useMemo } from "react";
import { FooterTemplateProps, TemplateProps } from "../TemplateProps";

const Footer: React.FC<TemplateProps> = React.memo(({
    ...props
}) => {
    // 獲取樣式設定
    const portalStyle = props.merchantPortalStyleSettingsMutation.data?.result?.find(x =>
        x.type === MerchantPortalStyleSettingType.Components &&
        x.name === MerchantPortalStyleShopComponents.Footer
    );

    const TemplateComponent = useMemo(() => {
        return dynamic<TemplateProps>(() =>
            import(`../../Templates/Shop/Components/Footer/Template_${portalStyle?.style || '0'}.tsx`),
            {
                loading: () => <></>,
                ssr: false
            }
        );
    }, [portalStyle]);

    if (props.footerProps?.isHideFooter) {
        return null;
    }

    return <TemplateComponent
        {...props}
    />;
});

export default Footer;
