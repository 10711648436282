import { domainSuffixes, getCurrentDomain } from '@/constants/routes';
import JSONBig from 'json-bigint';

export class GlobalVars {
    static async getMerchantId() {
        const url = new URL(window.location.href);
        let merchantId = '';
        let domainHost = getCurrentDomain();

        // 如果當前域名不在 domainSuffixes 中，通過 API 獲取 merchantId
        if (!domainSuffixes.includes(domainHost)) {
            try {
                const response = await fetch(`${process.env.BASE_API_URL}/api/v1/Portal/GetMerchantIdByDomain?domain=${encodeURIComponent(`${domainHost}`)}`);
                if (!response.ok) {
                    console.error('Failed to fetch merchant ID:', response.statusText);
                    return '';
                }
                const responseText = await response.text();
                const data = JSONBig.parse(responseText);
                if (!data.isSuccess) {
                    console.error('Failed to fetch merchant ID:', data.message);
                    return '';
                }
                return data.result.toString();
            } catch (error) {
                console.error('Error fetching merchant ID:', error);
                return ''; // 需要時返回空字符串或處理錯誤
            }
        }

        // 如果當前域名在 domainSuffixes 中，從 URL 路徑中提取 merchantId
        const pathSegments = url.pathname.split('/');
        merchantId = pathSegments[1];
        return merchantId;
    }
}

export const env = {
    BASE_API_URL: `${process.env.BASE_API_URL}`  // 請替換為你的實際 API URL
}

// 將 GlobalVars 添加到 window 對象
window.GlobalVars = GlobalVars;
window.env = env;
