import { CheckOutProvider } from "@/Templates/Shop/Components/ShoppingCart/Contexts/ICheckOutContext";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Col, Flex, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import Orders from "../MemberPages/Components/Orders";

const AnonymousOrderPages = () => {
    const {
        deviceType,
        merchantId,
        isLogin,
        tryGetSessionId,
        messageApi
    } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const [orderId, setOrderId] = useState<string | undefined>();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const orderId = params.get('orderId');
        if (orderId) {
            setOrderId(orderId);
        }
    }, []);

    return (
        <Row>
            <Col span={24}>
                <Flex justify="center" align="center" style={{ padding: '30px' }}>
                    <CheckOutProvider
                        merchantId={merchantId}
                        isLogin={isLogin}
                        tryGetSessionId={tryGetSessionId}
                        messageApi={messageApi}
                        translate={translate}
                    >
                        <Orders orderId={orderId} />
                    </CheckOutProvider>
                </Flex>
            </Col>
        </Row>
    )
}

export default AnonymousOrderPages