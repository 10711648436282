import domainLogo from '@/assets/Domigo-Logo-removebg-preview.png';
import { BASE_PATH } from '@/constants/routes';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import LoadingComponent from '@/Templates/components/LoadingCompoent';
import NavBar from '@/Templates/components/NavBar';
import { DeviceType, MerchantPortalStyleSettingType, MerchantPortalStyleShopComponents } from '@/Templates/enums/templateEnums';
import { ConfigProvider } from 'antd';
import { Locale } from 'antd/lib/locale';
import enUS from 'antd/locale/en_US';
import zhCN from 'antd/locale/zh_CN';
import zhTW from 'antd/locale/zh_TW';
import React, { useContext, useEffect } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useGetMerchantIsExpiredApi } from '../../lib/api/apis';
import { GlobalContext } from '../../lib/contexts/GlobalContext';
import MerchantClosedPage from '../MerchantClosedPages/MerchantClosedPage';
import FunctionalFloatButtonGroup from './Components/FunctionalFloatButtonGroup';
import MyRoutes from './Components/MyRoutes';
if (process.env.NODE_ENV === 'development') {
  //if development directly import fonts.css
  import('@/Templates/assets/fonts/fonts.css');
}


const App: React.FC = () => {
  const globalContext = useContext(GlobalContext);
  const translationContext = useContext(TranslationContext);
  const navigate = useNavigate();

  // 檢查兩個 context 是否都已經準備好
  if (!globalContext || !translationContext) {
    return <LoadingComponent />;
  }

  const {
    maxWidth,
    messageApi,
    notificationApi,
    merchantId,
    shoppingCartItemCount,
    isFullPageLoading,
    setIsFullPageLoading,
    merchantPortalStyleSettingsMutation,
    navBarHeight,
    navBarGap,
    isHideNavBar,
    merchantPortalOptionSettingMutation,
    setNavBarHeight,
    setNavBarGap,
    deviceType,
    setDeviceType,
    isLogin,
    setIsLogin,
    refreshShoppingCartItemCount,
    setIsHideFooter,
    initAppMutation,
    tryGetSessionId,
    handleLogout
  } = globalContext;
  const { translate, i18nLanguage } = translationContext;
  const { isLoading: isExpiredLoading, data: isExpiredValue } = useQuery('IsExpired', async () => await useGetMerchantIsExpiredApi(merchantId), {
    onError: (error) => {
      messageApi.error('Unknown error');
    }
  });

  useEffect(() => {
    initAppMutation.mutate({ merchantId: merchantId });
  }, []);

  useEffect(() => {
    if (isExpiredValue?.result) {
      navigate(`${BASE_PATH}/merchantClosed`);
    }
  }, [isExpiredValue]);


  const [locale, setLocale] = React.useState<Locale>(enUS);

  // Function to detect device type using visualViewport and userAgent
  const detectDeviceType = () => {
    const viewportWidth = window.visualViewport?.width || window.innerWidth;

    // Fallback to user agent for additional detection
    const userAgent = navigator.userAgent.toLowerCase();
    const isIOS = /iphone|ipad|ipod/.test(userAgent);
    const isAndroid = /android/.test(userAgent);

    if (viewportWidth <= 768 || isMobile || isIOS) {
      setDeviceType(DeviceType.Mobile);
      setNavBarHeight(50);
      setNavBarGap(50);
    } else if (viewportWidth <= 1024 || isTablet || isAndroid) {
      setDeviceType(DeviceType.LapTop);
      setNavBarHeight(60);
      setNavBarGap(60);
    } else {
      setDeviceType(DeviceType.Desktop);
      setNavBarHeight(60);
      setNavBarGap(60);
    }
  };

  useEffect(() => {
    // Initialize device type detection
    detectDeviceType();

    // Add event listeners for resize and orientation change
    const handleResize = () => detectDeviceType();
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  useEffect(() => {
    switch (i18nLanguage) {
      case 'zh_TW':
        setLocale(zhTW);
        break;
      case 'zh_CN':
        setLocale(zhCN);
        break;
      default:
        setLocale(enUS);
        break;
    }
  }, [i18nLanguage]);

  useEffect(() => {
    setIsFullPageLoading(false);
  }, []);

  if (initAppMutation.isLoading || isExpiredLoading) {
    return <></>
  }
  if (isExpiredValue?.result || merchantPortalOptionSettingMutation.data?.result?.isClosed || merchantPortalOptionSettingMutation.data?.result?.isBanned) {
    return <MerchantClosedPage />
  }

  const importHelmet = () => {
    if (process.env.NODE_ENV === 'development') {
      return <Helmet>
        <meta charSet="UTF-8" />
        <link rel="icon" type="image/*" href={merchantPortalOptionSettingMutation.data?.result?.merchantBrowserTabIconPath || merchantPortalOptionSettingMutation.data?.result?.merchantIconPath || domainLogo} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{merchantPortalOptionSettingMutation.data?.result?.merchantName}</title>
      </Helmet>
    }
    else {
      //if production load fonts.css from cdn and nginx
      return <Helmet>
        <meta charSet="UTF-8" />
        <link rel="icon" type="image/*" href={merchantPortalOptionSettingMutation.data?.result?.merchantBrowserTabIconPath || merchantPortalOptionSettingMutation.data?.result?.merchantIconPath || domainLogo} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{merchantPortalOptionSettingMutation.data?.result?.merchantName}</title>

        {/* 预加载字体文件 */}
        <link rel="preload" href="https://cdn.jsdelivr.net/gh/max32002/bakudaifont@1.48/webfont/Bakudai-Regular.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
        <link rel="preload" href="https://cdn.jsdelivr.net/gh/max32002/masafont@2.1/webfont/MasaFont-Regular.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
        <link rel="preload" href="/assets/fonts/SourceHanSerifTC-VF.ttf" as="font" type="font/ttf" crossOrigin="anonymous" />
        <link rel="preload" href="/assets/fonts/SourceHanSerifSC-VF.ttf" as="font" type="font/ttf" crossOrigin="anonymous" />

        {/* 加载字体CSS */}
        <link rel="stylesheet" href="/assets/fonts/fonts.css" />
      </Helmet>
    }
  };

  return (
    !isExpiredValue?.result && (
      <>
        {importHelmet()}
        <ConfigProvider locale={locale}>
          {/* NavBar */}
          <NavBar
            isLogin={isLogin}
            tryGetSessionId={tryGetSessionId}
            i18nLanguage={i18nLanguage}
            navBarGap={navBarGap}
            navBarHeight={navBarHeight}
            BASE_PATH={BASE_PATH}
            navBarProps={{
              isHideNavBar,
              deviceType,
              shoppingCartItemCount,
              isLogin,
              setNavBarHeight,
              setNavBarGap,
              setDeviceType,
              setIsLogin,
              refreshShoppingCartItemCount,
              handleLogout
            }}
            deviceType={deviceType}
            translate={translate}
            messageApi={messageApi}
            notificationApi={notificationApi}
            merchantId={merchantId}
            config={merchantPortalStyleSettingsMutation.data?.result?.filter(r => r.type === MerchantPortalStyleSettingType.Components && r.name === MerchantPortalStyleShopComponents.NavBar)[0]?.styleSetting || {}}
            merchantPortalOptionSettingMutation={merchantPortalOptionSettingMutation}
            merchantPortalStyleSettingsMutation={merchantPortalStyleSettingsMutation}
            navigate={navigate}
          />
          {/* Page Content max width 1024 px*/}
          <div style={{ maxWidth: maxWidth, margin: '0 auto' }}>
            <MyRoutes />
            <FunctionalFloatButtonGroup></FunctionalFloatButtonGroup>
          </div>
        </ConfigProvider>
      </>
    )
  );
};

export default App;
