import { BASE_PATH } from '@/constants/routes';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import Footer from '@/Templates/components/Footer';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

const AppFooter: React.FC = () => {
    const navigate = useNavigate();
    const {
        isLogin,
        tryGetSessionId,
        merchantId,
        isHideFooter,
        merchantPortalOptionSettingMutation,
        merchantPortalStyleSettingsMutation,
        navBarHeight,
        navBarGap,
        deviceType,
        messageApi,
        notificationApi
    } = useContext(GlobalContext);
    const { translate, i18nLanguage } = useContext(TranslationContext);
    const buildTime = process.env.BUILD_TIME;

    if (isHideFooter) {
        return null;
    }

    return (
        <Footer
            isLogin={isLogin}
            tryGetSessionId={tryGetSessionId}
            footerProps={{
                isHideFooter: isHideFooter,
                buildTime: buildTime || '',
            }}
            navBarHeight={navBarHeight}
            navBarGap={navBarGap}
            deviceType={deviceType}
            BASE_PATH={BASE_PATH}
            merchantId={merchantId}
            i18nLanguage={i18nLanguage}
            translate={translate}
            merchantPortalOptionSettingMutation={merchantPortalOptionSettingMutation}
            merchantPortalStyleSettingsMutation={merchantPortalStyleSettingsMutation}
            messageApi={messageApi}
            notificationApi={notificationApi}
            navigate={navigate}
        />
    );
};

export default AppFooter;
