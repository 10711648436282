import { useGetMerchantPortalStyleSettingsApi } from "@/lib/api/apis";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import MobileBottomNavigation from "@/Templates/components/MobileBottomNavigation";
import { MerchantPortalStyleSettingType, MerchantPortalStyleShopComponents } from "@/Templates/enums/templateEnums";
import { SignHelper } from "@/Templates/helpers/SignHelper";
import { IAddMemberLogisticOptionRequest, IBatchAddItemToTempShoppingCartRequest, IDeleteMemberLogisticOptionRequest, IShoppingCartItemViewModel } from "@/Templates/interfaces/templatesInterfaces";
import { useAddMemberLogisticOptionApi, useBatchAddItemToTempShoppingCartApi, useBatchRemoveItemsFromShoppingCartApi, useDeleteMemberLogisticOptionApi, useGetShoppingCartApi, useGetTempShoppingCartApi } from "@/Templates/lib/apis";
import DynamicPageTemplate from "@/Templates/Shop/Pages/DynamicPage/DynamicPageTemplate";
import { AnimatePresence, motion } from "framer-motion";
import JSONBig from 'json-bigint';
import React, { useContext, useEffect, useMemo } from "react";
import { useMutation } from "react-query";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import routes, { BASE_PATH } from '../../../constants/routes';
import { GlobalContext } from "../../../lib/contexts/GlobalContext";
import AppFooter from "./AppFooter";

const MyRoutes = () => {
  const navigate = useNavigate();
  const signHelper = new SignHelper();
  const {
    isLogin,
    tryGetSessionId,
    merchantId,
    navBarGap,
    sendGAEvent,
    isHideNavBar,
    merchantPortalStyleSettingsMutation,
    deviceType,
    merchantPortalOptionSettingMutation,
    messageApi,
    notificationApi,
    shoppingCartItemCount,
    refreshShoppingCartItemCount
  } = React.useContext(GlobalContext);
  const {
    translate,
    i18nLanguage,
    getCountrySelectionDisplayName
  } = useContext(TranslationContext);
  const location = useLocation();

  const switchPageFadeTime = merchantPortalStyleSettingsMutation?.data?.result?.find(r =>
    r.type === MerchantPortalStyleSettingType.Components &&
    r.name === MerchantPortalStyleShopComponents.NavBar)?.styleSetting?.switchPageFadeTime ?? 1.5;

  const useGetMerchantPortalStylesApiMutation = useMutation(async () => await useGetMerchantPortalStyleSettingsApi(merchantId));

  useEffect(() => {
    sendGAEvent('page_view', { page_path: `${location.pathname}` });
  }, [location.pathname]);

  useEffect(() => {
    useGetMerchantPortalStylesApiMutation.mutate();
  }, [merchantId]);

  const batchDeleteShoppingCartItemsMutation = useMutation(["batchDeleteShoppingCartItems"],
    async (items: IShoppingCartItemViewModel[]) => {
      return await useBatchRemoveItemsFromShoppingCartApi({
        requests: items.map(item => ({
          shoppingCartItems: [item]
        }))
      });
    }
  );

  const shoppingCartDataMutation = useMutation(["getShoppingCart"], async ({ isLogin }: { isLogin: boolean }) => {
    if (isLogin) {
      return await useGetShoppingCartApi(tryGetSessionId());
    }
    else {
      return await useGetTempShoppingCartApi(merchantId!, tryGetSessionId());
    }
  });
  const handleAddMemberLogisticOption = useMutation(
    ["addMemberLogisticOption"],
    async (request: IAddMemberLogisticOptionRequest) => {
      return await useAddMemberLogisticOptionApi(request);
    }
  );

  const handleDeleteMemberLogisticOption = useMutation(
    ["deleteMemberLogisticOption"],
    async (request: IDeleteMemberLogisticOptionRequest) => {
      return await useDeleteMemberLogisticOptionApi(request);
    }
  );


  const batchAddShoppingCartItemsMutation = useMutation(["batchAddShoppingCartItems"],
    async (items: IShoppingCartItemViewModel[]) => {
      var request: IBatchAddItemToTempShoppingCartRequest = {
        merchantId: merchantId!,
        sessionId: tryGetSessionId(),
        hash: '',
        addItemToShoppingCartItems: items
      }
      // 調用加入臨時購物車 API
      const st = `${merchantId}:${tryGetSessionId()}:${JSONBig.stringify(items)}`;
      const hash = await signHelper.generateDynamicTimeSignAsync(st.toLowerCase(), new Date());
      // 未登錄情況 - 使用臨時購物車API
      return await useBatchAddItemToTempShoppingCartApi({
        ...request,
        hash: hash
      });
    }
  );

  const memoizedRoutes = useMemo(() => (
    <div style={{ paddingTop: isHideNavBar ? 0 : `${navBarGap}px` }}>
      <AnimatePresence mode="wait">
        <motion.div
          style={{ width: '100%', height: '100%' }}
          key={location.pathname}
          initial={{ opacity: 0, x: 0 }}
          animate={{ opacity: 0.99, x: 0 }}
          transition={{ duration: switchPageFadeTime }}
        >
          <Routes location={location}>
            {Object.values(routes).map((route, index) => (
              <Route key={index} path={`${route.path}`} element={route.element} />
            ))}
            {useGetMerchantPortalStylesApiMutation.data?.
              result?.
              filter(r =>
                (r.type === MerchantPortalStyleSettingType.Pages || r.type === MerchantPortalStyleSettingType.DynamicPages)).
              map((route, index) => (
                <Route key={index} path={`${BASE_PATH}${route.pagePath}`} element={
                  <DynamicPageTemplate
                    isLogin={isLogin}
                    tryGetSessionId={tryGetSessionId}
                    components={route.dynamicComponents}
                    translate={translate}
                    deviceType={deviceType}
                    navBarHeight={0}
                    navBarGap={0}
                    BASE_PATH={BASE_PATH}
                    merchantId={merchantId}
                    i18nLanguage={i18nLanguage}
                    merchantPortalOptionSettingMutation={merchantPortalOptionSettingMutation}
                    merchantPortalStyleSettingsMutation={merchantPortalStyleSettingsMutation}
                    messageApi={messageApi}
                    notificationApi={notificationApi}
                    isPreviewMode={false}
                    navigate={navigate}
                    shoppingCartPageProps={{
                      shoppingCartDataMutation: shoppingCartDataMutation,
                      shoppingCartItemCount: shoppingCartItemCount,
                      refreshShoppingCartItemCount: refreshShoppingCartItemCount,
                      batchDeleteShoppingCartItemsMutation: batchDeleteShoppingCartItemsMutation,
                      batchAddShoppingCartItemsMutation: batchAddShoppingCartItemsMutation,
                      sendGAEvent: sendGAEvent,
                      getCountrySelectionDisplayName: getCountrySelectionDisplayName,
                      handleAddMemberLogisticOption: handleAddMemberLogisticOption,
                      handleDeleteMemberLogisticOption: handleDeleteMemberLogisticOption,
                    }}
                  />
                } />
              ))}

          </Routes>
          <AppFooter />
          <MobileBottomNavigation
            isLogin={isLogin}
            tryGetSessionId={tryGetSessionId}
            translate={translate}
            deviceType={deviceType}
            navBarHeight={0}
            navBarGap={0}
            BASE_PATH={BASE_PATH}
            merchantId={merchantId}
            i18nLanguage={i18nLanguage}
            merchantPortalOptionSettingMutation={merchantPortalOptionSettingMutation}
            merchantPortalStyleSettingsMutation={merchantPortalStyleSettingsMutation}
            messageApi={messageApi}
            notificationApi={notificationApi}
            isPreviewMode={false}
            navigate={navigate}
          />
        </motion.div>
      </AnimatePresence>
    </div>
  ), [navBarGap, isHideNavBar, location, useGetMerchantPortalStylesApiMutation.data]);

  if (useGetMerchantPortalStylesApiMutation.isLoading) {
    return <></>;
  }

  if (!useGetMerchantPortalStylesApiMutation.data) {
    return <></>;
  }
  return memoizedRoutes;
};

export default MyRoutes;
