import domainLogo from '@/assets/Domigo-Logo-removebg-preview.png';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Result, Typography } from 'antd';
import { useContext } from 'react';
import { Helmet } from 'react-helmet';

const { Paragraph, Text } = Typography;

const MerchantClosedPage = () => {

    const { translate } = useContext(TranslationContext);
    const { merchantPortalOptionSettingMutation } = useContext(GlobalContext);

    return (
        <>
            <Helmet>
                <meta charSet="UTF-8" />
                <link rel="icon" type="image/*" href={merchantPortalOptionSettingMutation.data?.result?.merchantIconPath || domainLogo} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>{merchantPortalOptionSettingMutation.data?.result?.merchantName}</title>
            </Helmet>
            <Result
                status="error"
                title={translate('Not available')}
            >
                <div className="desc">
                    {
                        merchantPortalOptionSettingMutation.data?.result?.isBanned ? (
                            <Paragraph>
                                <CloseCircleOutlined className="site-result-demo-error-icon" />
                                {translate('Due to the merchant\'s suspension, the merchant portal is currently unavailable.')}
                            </Paragraph>
                        ) : merchantPortalOptionSettingMutation.data?.result?.isClosed ? (
                            <Paragraph>
                                <CloseCircleOutlined className="site-result-demo-error-icon" />
                                {translate('Merchant is closed')}
                            </Paragraph>
                        ) : null
                    }
                </div>
            </Result>
        </>
    )
}

export default MerchantClosedPage
