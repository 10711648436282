import { LoginTypeEnum } from "@/Templates/enums/templateEnums";
import { LockOutlined } from "@ant-design/icons";
import { Button, Flex, Form, Input, InputRef } from "antd";
import FormItem from "antd/es/form/FormItem";

// 登入表單屬性介面
interface LoginFormProps {
    form: any;
    emailRef: React.RefObject<InputRef>;
    loginRequest: any;
    handelChange: (type: string, value: string) => void;
    handelSubmit: (value: any) => void;
    isLoading: boolean;
    translate: (key: string) => string;
    merchantId: BigInt;
    loginType: LoginTypeEnum;
}

// 登入表單組件
const LoginForm: React.FC<LoginFormProps> = ({
    form,
    emailRef,
    loginRequest,
    handelChange,
    handelSubmit,
    isLoading,
    translate,
    merchantId,
    loginType
}) => {
    return (
        <Form
            form={form}
            name="login-form"
            initialValues={{
                email: loginRequest.email,
                hashPassword: loginRequest.hashPassword,
            }}
            onFinish={handelSubmit}
        >
            {loginType === LoginTypeEnum.Email ? (
                <FormItem
                    name="email"
                    rules={[
                        { required: true, message: translate('is required') },
                        { type: 'email', message: translate('Invalid format') }
                    ]}
                >
                    <Input
                        ref={emailRef}
                        placeholder={translate('Email')}
                        onChange={(e) => handelChange('email', e.target.value)}
                        value={loginRequest.email}
                    />
                </FormItem>
            ) : (
                <FormItem
                    name="phoneNumber"
                    rules={[
                        { required: true, message: translate('is required') },
                        { pattern: /^[0-9]{10}$/, message: translate('Invalid format') }
                    ]}
                >
                    <Input
                        placeholder={translate('Phone Number')}
                        onChange={(e) => handelChange('phoneNumber', e.target.value)}
                        value={loginRequest.phoneNumber}
                    />
                </FormItem>
            )}

            <FormItem
                name="hashPassword"
                rules={[
                    { required: true, message: translate('is required') },
                ]}
            >
                <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder={translate("Password")}
                    value={loginRequest.hashPassword}
                    onChange={(e) => handelChange("hashPassword", e.target.value)}
                />
            </FormItem>

            <FormItem>
                <Flex justify="center" align="center">
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button mr-2"
                        loading={isLoading}
                    >
                        {translate("Login")}
                    </Button>
                </Flex>
            </FormItem>
        </Form>
    );
};

export default LoginForm; 