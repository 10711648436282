import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { MerchantPortalStyleSettingType, MerchantPortalStyleShopComponents } from "@/Templates/enums/templateEnums";
import { NavBarTemplateProps, TemplateProps } from "@/Templates/TemplateProps";
import dynamic from "next/dynamic";
import React, { useCallback, useContext, useMemo } from "react";

const NavBar: React.FC<TemplateProps> = React.memo(({
    ...props
}) => {
    const portalStyle = props.merchantPortalStyleSettingsMutation.data?.result?.find(x => x.type === MerchantPortalStyleSettingType.Components && x.name === MerchantPortalStyleShopComponents.NavBar);

    const TemplateComponent = useMemo(() => {
        return dynamic<TemplateProps>(() =>
            import(`../../Templates/Shop/Components/NavBar/Template_${portalStyle?.style || '0'}.tsx`),
            {
                loading: () => <></>,
                ssr: false
            }
        );
    }, [props]);

    return <TemplateComponent
        {...props}
    />;
});

export default NavBar;
