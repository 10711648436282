$(document).ready(async function () {
    try {
        // 使用 GlobalVars 獲取 merchantId
        const merchantId = await window.GlobalVars.getMerchantId();
        if (!merchantId) {
            throw new Error('Failed to get merchant ID');
        }

        // 使用正則表達式檢查是否為純數字
        if (!/^\d+$/.test(merchantId)) {
            throw new Error('Invalid merchant ID: must be numeric only');
        }

        // 獲取商戶設置
        const response = await fetch(
            `${window.env.BASE_API_URL}/api/v1/Portal/GetMerchantPortalStyleSettings?merchantId=${merchantId}`
        );
        const data = await response.json();

        if (!data.isSuccess) {
            throw new Error(data.message || 'Failed to fetch merchant settings');
        }

        const logoElement = document.getElementById('loading-logo');
        const fullPageLoading = data.result.find(x => x.type === 'Components' && x.name === "FullPageLoading");

        if (fullPageLoading?.styleSetting) {
            const isEnabled = fullPageLoading.styleSetting.isEnable === 'true';

            if (!isEnabled) {
                const loadingWatermark = document.getElementById('loading-watermark');
                loadingWatermark?.remove();
            } else if (fullPageLoading.styleSetting.images) {
                const images = JSON.parse(fullPageLoading.styleSetting.images);
                if (images.length > 0) {
                    logoElement.src = images[0];
                    logoElement.style.transition = 'opacity 0.5s ease-in-out';
                }
            }
        }
    } catch (error) {
        console.error('Initialization error:', error);
    }
});