import { GenderEnum, MemberPassOTPVerifyType } from "@/enums/Enums";
import { IAlterMemberInfoRequest, SendRegistOTPRequest } from "@/interfaces/Requests";
import { useAlterMemberInfoApi, useGetMemberInfoApi, useSendRegistOTPApi } from "@/lib/api/Portal/members";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import { LoginTypeEnum } from "@/Templates/enums/templateEnums";
import { Button, DatePicker, Flex, Form, Input, Select } from "antd";
import dayjs from "dayjs";
import React, { useContext, useEffect, useImperativeHandle, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

export interface IMemberInfoProps { }

export interface IMemberInfoRef {
    onRefresh: () => void;
}

const MemberInfo = React.forwardRef((props: IMemberInfoProps, ref: React.ForwardedRef<IMemberInfoRef | undefined>) => {
    const { messageApi, deviceType } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const navigate = useNavigate();
    const [emailCooldown, setEmailCooldown] = useState(0);
    const [phoneCooldown, setPhoneCooldown] = useState(0);
    const getMemberInfoMutation = useMutation("GetMemberInfo", async () => await useGetMemberInfoApi());
    const { mutate: alterMemberInfoMutate, isLoading: alterMemberInfoLoading } = useMutation(async (request: IAlterMemberInfoRequest) => await useAlterMemberInfoApi(request), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Operation success'));
                getMemberInfoMutation.mutate();
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });
    const { mutate: sendRegistOTPMutate, isLoading: sendRegistOTPLoading } = useMutation(async (reqeust: SendRegistOTPRequest) => await useSendRegistOTPApi(reqeust), {
        onSuccess: (response, reqeust) => {
            if (response.isSuccess) {
                // 成功時直接設定60秒冷卻
                if (reqeust.Type === MemberPassOTPVerifyType.Email) {
                    setEmailCooldown(response.result || 0);
                } else {
                    setPhoneCooldown(response.result || 0);
                }
                messageApi.success(translate('Operation success'));
            } else {
                // 失敗時設置API返回的冷卻時間
                if (reqeust.Type === MemberPassOTPVerifyType.Email) {
                    setEmailCooldown(response.result || 0);
                } else {
                    setPhoneCooldown(response.result || 0);
                }
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    // 處理倒數計時
    useEffect(() => {
        let emailTimer: NodeJS.Timeout;
        if (emailCooldown > 0) {
            emailTimer = setInterval(() => {
                setEmailCooldown(prev => prev - 1);
            }, 1000);
        }
        return () => clearInterval(emailTimer);
    }, [emailCooldown]);

    useEffect(() => {
        let phoneTimer: NodeJS.Timeout;
        if (phoneCooldown > 0) {
            phoneTimer = setInterval(() => {
                setPhoneCooldown(prev => prev - 1);
            }, 1000);
        }
        return () => clearInterval(phoneTimer);
    }, [phoneCooldown]);

    const [form] = Form.useForm();

    const memberInfoTranslate = (key: string) => {
        key = key.charAt(0).toUpperCase() + key.slice(1);
        if (key === 'Id') {
            return translate('Member ID');
        }
        if (key === "IsBanned") {
            return translate("Banned");
        }
        if (key === "PhoneNumber") {
            return translate("Phone number");
        }
        if (key === 'RegistDate') {
            return translate('Registration date')
        }
        if (key === 'TotalConsumption') {
            return translate('Total consumption')
        }
        if (key === 'TotalOrderCount') {
            return translate('Total order count')
        }
        if (key === 'LoginType') {
            return translate('LoginType')
        }
        if (key === 'MerchantId') {
            return translate('Merchant ID')
        }
        if (key === 'ThirdPartyLoginProviders') {
            return translate('ThirdPartyLoginProvider')
        }
        return translate(key);
    }

    const disableCheck = (key: string, loginType: LoginTypeEnum) => {
        key = key.charAt(0).toUpperCase() + key.slice(1);
        if (key === 'PhoneNumber') {
            return getMemberInfoMutation.data?.result?.isPhoneNumberOTPValidate;
        }
        if (key === 'Email') {
            return getMemberInfoMutation.data?.result?.isEmailOTPValidate;
        }
        return !["UserName", "Birthday", "Gender"].includes(key);
    }

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            const formData = { ...values };
            if (formData.birthday) {
                formData.birthday = dayjs(formData.birthday).format('YYYY-MM-DD');
            }
            alterMemberInfoMutate(formData);
        });
    }

    useEffect(() => {
        if (getMemberInfoMutation.data?.result) {
            const formData = { ...getMemberInfoMutation.data.result };
            form.setFieldsValue(formData);
        }
    }, [getMemberInfoMutation.data]);

    useEffect(() => {
        getMemberInfoMutation.mutate();
    }, []);

    const renderFormItem = (key: string, value: any, loginType: LoginTypeEnum) => {
        if ((key === 'thirdPartyLoginProviders' || key === 'uniqueId')) {
            if (loginType === LoginTypeEnum.ThirdPartyLogin)
                return (
                    <Form.Item name={key} label={memberInfoTranslate(key)} key={key}>
                        <Input disabled={disableCheck(key, loginType)} />
                    </Form.Item>
                );
            else
                return <></>;
        }

        //ignore key 
        if (key === "hasUsedTrial" || key === "userId" || key === 'hashPassword') {
            return;
        }
        if (key === "loginType") {
            return (
                <Form.Item name={key} label={memberInfoTranslate(key)} key={key}>
                    <Select disabled={disableCheck(key, loginType)}>
                        {Object.keys(LoginTypeEnum).map((type) => (
                            <Select.Option key={type} value={type}>
                                {translate(type)}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            );
        }
        if (key === "gender") {
            return (
                <Form.Item name={key} label={memberInfoTranslate(key)} key={key}>
                    <Select>
                        {Object.keys(GenderEnum).map((genderKey) => (
                            <Select.Option key={genderKey} value={genderKey}>
                                {translate(genderKey)}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            );
        }

        if (key === "birthday") {
            return (
                <Form.Item
                    name={key}
                    label={memberInfoTranslate(key)}
                    key={key}
                    getValueProps={(i) => ({ value: dayjs(i) })}
                >
                    <DatePicker
                        disabled={disableCheck(key, loginType)}
                        format="YYYY-MM-DD"
                        placeholder="YYYY-MM-DD"
                        style={{ width: '100%' }}
                        allowClear={false}
                    />
                </Form.Item>
            );
        }
        if (key === "isBanned") {
            return (
                <Form.Item name={key} label={memberInfoTranslate(key)} key={key}>
                    <Select disabled>
                        <Select.Option key="true" value={true}>{translate('Yes')}</Select.Option>
                        <Select.Option key="false" value={false}>{translate('No')}</Select.Option>
                    </Select>
                </Form.Item>
            );
        }

        if (key === 'isEmailOTPValidate') {
            // email is not empty
            if (getMemberInfoMutation.data?.result?.email === '') {
                return <></>;
            }
            return (
                <>
                    <Form.Item name={key} label={memberInfoTranslate(key)} key={key}>
                        <Select disabled>
                            <Select.Option value={true}>{translate('Yes')}</Select.Option>
                            <Select.Option value={false}>{translate('No')}</Select.Option>
                        </Select>
                    </Form.Item>
                    {
                        value === false &&
                        <Form.Item key={`email-otp-button-${key}`}>
                            <Flex justify="center">
                                <Button
                                    type="primary"
                                    onClick={() => { sendRegistOTPMutate({ Type: MemberPassOTPVerifyType.Email }); }}
                                    disabled={sendRegistOTPLoading || alterMemberInfoLoading || emailCooldown > 0}
                                    style={{ marginLeft: '10px' }}
                                >
                                    {translate('Send')} {translate('Email')} {translate('OTP')}
                                    {emailCooldown > 0 && ` (${emailCooldown}s)`}
                                </Button>
                            </Flex>
                        </Form.Item>
                    }
                </>
            );
        };

        if (key === 'isPhoneNumberOTPValidate') {
            // phone number is not empty, phone number is empty
            if (getMemberInfoMutation.data?.result?.phoneNumber === '') {
                return <></>;
            }
            return (
                <>
                    <Form.Item name={key} label={memberInfoTranslate(key)} key={key}>
                        <Select disabled>
                            <Select.Option key="true" value={true}>{translate('Yes')}</Select.Option>
                            <Select.Option key="false" value={false}>{translate('No')}</Select.Option>
                        </Select>
                    </Form.Item>
                    {
                        value === false &&
                        <Form.Item key={`phone-otp-button-${key}`}>
                            <Flex justify="center">
                                <Button
                                    type="primary"
                                    onClick={() => { sendRegistOTPMutate({ Type: MemberPassOTPVerifyType.PhoneNumber }); }}
                                    disabled={sendRegistOTPLoading || alterMemberInfoLoading || phoneCooldown > 0}
                                    style={{ marginLeft: '10px' }}
                                >
                                    {translate('Send')} {translate('Phone Number')} {translate('OTP')}
                                    {phoneCooldown > 0 && ` (${phoneCooldown}s)`}
                                </Button>
                            </Flex>
                        </Form.Item>
                    }
                </>
            );
        };

        if (key === "registDate") {
            return (
                <Form.Item
                    name={key}
                    label={memberInfoTranslate(key)}
                    key={key}
                    getValueProps={(i) => ({ value: dayjs(i) })}
                >
                    <DatePicker
                        disabled={true}
                        format="YYYY-MM-DD HH:mm:ss"
                        placeholder="YYYY-MM-DD HH:mm:ss"
                        style={{ width: '100%' }}
                        showTime
                        allowClear={false}
                    />
                </Form.Item>
            );
        }

        return (
            <Form.Item name={key} label={memberInfoTranslate(key)} key={key}>
                <Input disabled={disableCheck(key, loginType)} />
            </Form.Item>
        );
    }

    if (getMemberInfoMutation.isLoading) {
        return <LoadingComponent />;
    }

    return (
        <>
            <Form
                style={{
                    width: '100%',
                    padding: '30px'
                }}
                form={form}
                key="memberInfoForm"
            >
                {getMemberInfoMutation.data?.result && Object.entries(getMemberInfoMutation.data.result).map(([key, value]) => (
                    <React.Fragment key={`form-item-${key}`}>
                        {renderFormItem(key, value, getMemberInfoMutation.data.result!['loginType'])}
                    </React.Fragment>
                ))}
            </Form>
            <Flex justify="center">
                <Button
                    loading={sendRegistOTPLoading || alterMemberInfoLoading}
                    type="primary"
                    onClick={handleSubmit}
                >
                    {translate("Save")}
                </Button>
            </Flex>
        </>
    );
});

export default MemberInfo;
