import { withContexts } from '@/components/withContexts';
import FacebookCallbackPage from '@/lib/thirdParty/FacebookCallbackPage';
import GoogleCallbackPage from '@/lib/thirdParty/GoogleCallbackPage';
import LineCallbackPage from "@/lib/thirdParty/LineCallbackPage";
import AnonymousOrderPages from '@/pages/AnonymousOrderPages/AnonymousOrderPages';
import ProxyLoginPage from '@/pages/Auth/ProxyLoginPage';
import { HtmlPage } from '@/pages/FunctionalDisplayPages/HtmlPage';
import PageFormPost from '@/pages/FunctionalDisplayPages/PageFormPost';
import QRCodePage from '@/pages/FunctionalDisplayPages/QRCodePage';
import MerchantClosedPage from '@/pages/MerchantClosedPages/MerchantClosedPage';
import { Result } from 'antd';
import React from 'react';
import { IRoute } from '../interfaces/Router';
import LoginPage from "../pages/Auth/LoginPage";
import MemberInfoPage from "../pages/MemberPages/MemberInfoPage";
import NotFoundPage from "../pages/NotFoundPages/NotFoundPage";
import InvoiceTemplate from '@/Templates/Shop/Components/ShoppingCart/InvoiceTemplates/InvoiceTemplate';

//MicroShop Free Domain
export const domainSuffixes = ["portal.junxiaotech.com", "portal.domigo-digital.com", "localhost", "portal.localhost"];
export const getCurrentDomain = () => {
    if (typeof window !== 'undefined') {
        return window.location.hostname;
    }
    return '';  // Default value if `window` is not defined (e.g., during server-side rendering)
};

const domainMatchesAnySuffix = (currentDomain: string, suffixes: string[]) => {
    return suffixes.some(suffix => currentDomain.endsWith(suffix));
}

const currentDomain = getCurrentDomain();
export const BASE_PATH = domainMatchesAnySuffix(currentDomain, domainSuffixes)
    ? `/${window.location.pathname.split('/')[1] || 'unknown'}`
    : '';
export const BASE_WEBSOCKET_PATH =
    domainMatchesAnySuffix(currentDomain, domainSuffixes)
        ? `${currentDomain}/${window.location.pathname.split('/')[1] || 'unknown'}`
        : '';

export const IsFreeDomain = domainMatchesAnySuffix(currentDomain, domainSuffixes);

const DevPageWrapper = ({
    deviceType,
    merchantId,
    translate, getCountrySelectionDisplayName,
    merchantPortalOptionSetting,
    invoiceValues,
    setInvoiceValues,
    invoiceValidate,
    setInvoiceValidate
}) => (
    <InvoiceTemplate
        deviceType={deviceType}
        merchantId={merchantId}
        translate={translate}
        getCountrySelectionDisplayName={getCountrySelectionDisplayName}
        merchantPortalOptionSetting={merchantPortalOptionSetting}
        invoiceValues={invoiceValues}
        setInvoiceValues={setInvoiceValues}
        invoiceValidate={invoiceValidate}
        setInvoiceValidate={setInvoiceValidate}
    />
);

//注意： siteMap 有定義部分路由，要修改前請先確認
//BASE_PATH is required if you use free domain (MicroShop)
const routes: { [key: string]: IRoute } = {
    // Global Pages (isGlobalPage: true)
    ErrorPage: {
        path: `${BASE_PATH}/error`,
        element: <Result status='error' />,
        isGlobalPage: true
    },
    LineCallbackPage: {
        path: `/line-login-callback`,
        element: <LineCallbackPage />,
        isGlobalPage: true
    },
    FacebookCallbackPage: {
        path: `/facebook-login-callback`,
        element: <FacebookCallbackPage />,
        isGlobalPage: true
    },
    GoogleCallbackPage: {
        path: `/google-login-callback`,
        element: <GoogleCallbackPage />,
        isGlobalPage: true
    },
    ProxyLoginPage: {
        path: `${BASE_PATH}/proxy-login`,
        element: <ProxyLoginPage />,
        isGlobalPage: true
    },

    // Regular Pages (isGlobalPage: false or undefined)
    AnonymousOrderPage: {
        path: `${BASE_PATH}/anonymousOrder`,
        element: <AnonymousOrderPages />
    },
    DevPage: {
        path: `${BASE_PATH}/dev`,
        element: React.createElement(withContexts(DevPageWrapper))
    },
    HtmlPage: {
        path: `${BASE_PATH}/html`,
        element: <HtmlPage />
    },
    LoginPage: {
        path: `${BASE_PATH}/login`,
        element: <LoginPage />
    },
    MemberInfoPage: {
        path: `${BASE_PATH}/memberInfo`,
        element: <MemberInfoPage />
    },
    MerchantClosedPage: {
        path: `${BASE_PATH}/merchantClosed`,
        element: <MerchantClosedPage />
    },
    NotFoundPage: {
        path: `${BASE_PATH}/*`,
        element: <NotFoundPage />
    },
    PageFormPost: {
        path: `${BASE_PATH}/pageFormPost`,
        element: <PageFormPost />
    },
    QRCodePage: {
        path: `${BASE_PATH}/qrCode`,
        element: <QRCodePage />
    }
}

export default routes;
